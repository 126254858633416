import React from 'react'

function Container({children}) {
  return (
    <div    className="md:w-10/12 mx-auto px-5">
      {children}
    </div>
  )
}

export default Container