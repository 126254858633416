import { Link } from "react-router-dom";
import site_info from "../assets/profile";

const Team = ({ team }) => {
  return (
    <div className="bg-gray-50 pb-10">
      <div className="lg:pb-24  pt-10 max-w-screen-lg mx-auto">
        <div className="text-center mb-16 px-8">
          <p className="mt-4 text-sm leading-7 text-gray-500 font-regular">
            THE TEAM
          </p>
          <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
            Our<span className="text-indigo-600"> Team</span>
          </h3>
          <p className=" mb-6 font-light text-gray-700 md:text-lg mt-3 pb-10 lg:pb-0">
            Meet the dedicated professionals behind
            <strong> {site_info.name}</strong> Our teams bring together a wealth
            of experience, diverse skills, and a shared commitment to exceeding
            client expectations. Learn more about the people who make our
            projects a success
          </p>
        </div>
        <div className="lg:flex justify-center items-center ">
          {team.map((dir, index) => {
            return (
              dir.imgUrl && (
                <div className="p-4 lg:w-4/12 ">
                  <div className="h-full   overflow-hidden">
                    <img className=" w-full " src={dir.imgUrl} alt="director" />
                    <div className="p-6">
                      <h2 className="tracking-widest text-center text-xs title-font font-medium text-gray-400 mb-1">
                        {dir.name}
                      </h2>
                      <h2 className="tracking-widest text-center text-xs title-font font-medium text-gray-400 mb-1 uppercase">
                        {dir.position}
                      </h2>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>

      <div className=" pb-12 bg-slate-900 mx-5 mb-10">
        <div className=" lg:grid grid-cols-2 lg:grid-cols-4 gap-5 max-w-screen-lg mx-auto p-5  "></div>
        <div className="lg:grid grid-cols-2 max-w-screen-lg gap-5 mx-auto mt-10 pb-10 px-5 lg:px-0 space-y-10 lg:space-y-0">
          <div className="  text-white">
            <div className="vision">
              <div className="title flex gap-3 items-center pb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 391.77 428.47"
                  className="w-10"
                >
                  <defs>
                    <style>{".cls-1{fill:#fff}"}</style>
                  </defs>
                  <path
                    d="M285.35 103.5a128.81 128.81 0 0 0-94.24-36.55c-66.93 2-122.12 55.85-125.63 122.66a130.21 130.21 0 0 0 48.72 108.46c14.71 11.73 23.5 30.57 23.5 50.39v1.74a11.26 11.26 0 0 0 11.27 11.22h92.08a11.26 11.26 0 0 0 11.26-11.26v-1.73c0-19.7 9.18-38.56 25.17-51.75a129.71 129.71 0 0 0 7.88-193.18ZM163.7 238.95h-13.68a10.06 10.06 0 1 1 0-20.11h3.63a10.06 10.06 0 0 1 10.05 10.11Zm41.84 99.91h-19.31v-77.35h19.31Zm57.59-59.59c-18.63 15.36-30.28 36.69-32.83 59.59h-2.23v-77.35h13.68a32.59 32.59 0 1 0 0-65.17h-3.62a32.63 32.63 0 0 0-32.59 32.61v10h-19.31v-10a32.62 32.62 0 0 0-32.58-32.58h-3.63a32.59 32.59 0 0 0 0 65.17h13.68v77.38h-4c-2.41-23.25-13.59-44.2-31.5-58.48a107.64 107.64 0 0 1-40.23-89.69c2.9-55.19 48.49-99.69 103.79-101.32a107.18 107.18 0 0 1 71.36 189.87Zm-35.06-40.32v-10a10.07 10.07 0 0 1 10.06-10.05h3.62a10.06 10.06 0 1 1 0 20.11ZM233.4 372.42h-75.08a11.27 11.27 0 1 0 0 22.53h75.08a11.27 11.27 0 0 0 0-22.53ZM217.64 405.95h-43.56a11.27 11.27 0 0 0 0 22.53h43.56a11.27 11.27 0 0 0 0-22.53ZM195.89 50.95a11.27 11.27 0 0 0 11.26-11.27V11.27a11.27 11.27 0 1 0-22.53 0v28.42a11.28 11.28 0 0 0 11.27 11.26ZM309.48 92.21a11.22 11.22 0 0 0 8.37-3.72l19-21.13a11.27 11.27 0 0 0-16.75-15.08l-19 21.13a11.26 11.26 0 0 0 8.37 18.8ZM380.51 183.45h-28.43a11.27 11.27 0 0 0 0 22.53h28.43a11.27 11.27 0 0 0 0-22.53ZM335.51 312.5l-23-16.71a11.264 11.264 0 1 0-13.25 18.22l23 16.71a11.261 11.261 0 1 0 13.24-18.22ZM73.97 88.49a11.27 11.27 0 0 0 16.74-15.08l-19-21.13a11.27 11.27 0 0 0-16.74 15.08ZM39.7 183.45H11.27a11.27 11.27 0 1 0 0 22.53H39.7a11.27 11.27 0 0 0 0-22.53ZM79.26 295.79l-23 16.71a11.261 11.261 0 1 0 13.24 18.22l23-16.71a11.261 11.261 0 1 0-13.24-18.22Z"
                    className="cls-1"
                  />
                </svg>
                <h3 className="title font-extrabold text-lg uppercase ">
                  Our Vision
                </h3>
              </div>
              <p className="body">
                To be renowned in the provision of technical and non-technical
                solutions to our esteemed clients with assurances driven by
                quality, integrity and safety.
              </p>
            </div>
          </div>
          <div className=" text-white">
            <div className="mission ">
              <div className="title flex gap-3 items-center pb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 452.29 451.8"
                  className="w-10"
                >
                  <defs>
                    <style>{".cls-1{fill:#fff}"}</style>
                  </defs>
                  <path
                    d="M415.44 207.72a10.82 10.82 0 1 0-21.63 0c0 102.6-83.48 186.08-186.09 186.08S21.63 310.32 21.63 207.72 105.11 21.61 207.72 21.61a185.5 185.5 0 0 1 99.42 28.75 10.813 10.813 0 1 0 11.57-18.27 207.07 207.07 0 0 0-111-32.11C93.17-.02-.01 93.16-.01 207.7a207.64 207.64 0 0 0 87.54 169.33l-40.81 57.71a10.812 10.812 0 1 0 17.66 12.48l41.41-58.61a207.37 207.37 0 0 0 203.86 0l41.43 58.57a10.812 10.812 0 0 0 17.66-12.48l-40.81-57.67a207.64 207.64 0 0 0 87.51-169.31Z"
                    className="cls-1"
                  />
                  <path
                    d="M280.18 96.73a10.8 10.8 0 0 0-4.34-14.66 143.08 143.08 0 0 0-68.12-17.26c-78.79 0-142.9 64.11-142.9 142.91s64.11 142.89 142.9 142.89 142.9-64.11 142.9-142.9a10.82 10.82 0 1 0-21.63 0 121.19 121.19 0 1 1-63.46-106.64 10.8 10.8 0 0 0 14.65-4.34Z"
                    className="cls-1"
                  />
                  <path
                    d="M207.72 150.61a57.08 57.08 0 0 1 29.39 8.13 10.813 10.813 0 1 0 11.15-18.53 78.74 78.74 0 1 0 38.19 67.51 10.82 10.82 0 1 0-21.63 0 57.1 57.1 0 1 1-57.1-57.11Z"
                    className="cls-1"
                  />
                  <path
                    d="M452.14 110.1a10.8 10.8 0 0 0-8.56-8.81l-38.8-7.68 6.37-42.52A10.81 10.81 0 0 0 395 40.15l-82 48a10.81 10.81 0 0 0-5.24 7.73l-6.94 46.27-98.42 56.17a10.82 10.82 0 0 0 10.72 18.79l97.76-55.79 46.5 9.19a11.49 11.49 0 0 0 2.1.2 10.79 10.79 0 0 0 5.46-1.48l82-48a10.81 10.81 0 0 0 5.2-11.13Zm-123.8-5.89 58.07-34-3.78 25.2-57.93 33.06Zm29.19 44.28-18-3.55 54.88-31.33 17 3.36Z"
                    className="cls-1"
                  />
                </svg>
                <h3 className="title font-extrabold text-lg uppercase ">
                  Our Mission
                </h3>
              </div>
              <p className="body ">
                To create and manage assets through reliable engineering,
                operations and maintenance for the good of our environment and
                people.
              </p>
            </div>
          </div>
        </div>
        <hr className="col-span-2 max-w-screen-lg mx-auto " />
      </div>
    </div>
  );
};

export default Team;
