import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import site_info from "../assets/profile";
import Header from "../components/shared/header";
import PageHero from "./page_hero";
import dredgin2 from "../assets/imgs/dregin2.png";

import nnpc from "../assets/imgs/clientsid/NNPC.png";
import shell from "../assets/imgs/clientsid/Shell-logo-1.jpg";
import nexus from "../assets/imgs/clientsid/nexus.jpeg";
import quest from "../assets/imgs/clientsid/quest.png";
import stemco from "../assets/imgs/clientsid/logo-Capture.jpg";
import planet from "../assets/imgs/clientsid/PLANET-PROJECTS-LOGO.png";
import loss from "../assets/imgs/clientsid/loss.png";

import Footer from "../components/shared/footer";
import "./services.css";

function Service() {
  // useEffect
  useEffect(() => {
    window.scrollTo(0, 0);
    let page_title = window.document.querySelector("head > title");
    page_title.textContent = "Mondore | Services";
    return () => {};
  }, []);

  const clients = [quest, stemco,  planet, loss, nexus, shell, nnpc];

  return (
    <div className="overflow-x-hidden">
      <Header />
      <div className="bg_services">
        <div className="bg-black bg-opacity-10">
          <PageHero page_title={"Our Services"} myClass={"text-white"} />
        </div>
      </div>
      <section className="md:pt-10 pt-5  mx-auto">
        <div className="flex flex-row max-w-screen-lg mx-auto px-5 ">
          <div className="basis-4/4 md:basis-3/4 ">
            <h3 className="text-primary font-bold text-3xl py-5">
              Service Overview
            </h3>
            <p className="sm: pr-5 ">
              At Mondore Ltd, we pride ourselves on being a leading provider of
              comprehensive engineering and related services, specializing in
              meeting the diverse needs of the Nigerian Oil and Gas industry.
              Since our establishment in 2020, we have consistently delivered
              superior solutions through a combination of technical expertise,
              strategic alliances, and a commitment to the highest industry
              standards.
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="hidden md:block basis-1/4 bg-dark text-center self-start"
          >
            <div className="py-20">
              <h3 className="py-5 font-bold text-xl text-white">
                Have a Question? <br />
                Call us Today
              </h3>
              <h3 className="font-bold text-xl text-white">
                {site_info.phone}
              </h3>
              <p className="text-sm text-warning">{site_info.email}</p>
            </div>
          </div>
        </div>
        <div className="container mx-auto  px-5 lg:bg-transparent bg-gray-50">
          <div className="maintenace">
            <div className="lg:grid grid-cols-6 gap-7 gap-y-10 lg:space-y-0 lg:mt-16 space-y-16 py-10 lg:w-11/12 mx-auto">
              <div
                className="col-span-2 overflow-hidden"
                data-aos="fade-right "
              >
                <img
                  src="https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_500,w_800/mondorelimited/services/dredgin_qxjqgx.jpg"
                  alt="drdge"
                  className="transition-all ease-linear hover:scale-105  "
                />
                <div className="py-10  rounded-b   bg-gray-200 px-5 ">
                  <h3 className="text-[#282828] font-bold text-xl uppercase">
                    Dredging Services
                  </h3>
                  <p className=" text-black  mt-5 ">
                    Mondore Ltd utilizes cutting-edge dredging techniques for
                    precise navigability enhancement. Whether for port
                    development, channel maintenance, or environmental
                    remediation, our focus is on efficient and environmentally
                    sustainable solutions.
                  </p>
                </div>
              </div>
              <div className="col-span-2" data-aos="fade-up">
                <img
                  src="https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_500,w_800/mondorelimited/services/piping_bqcm3n.jpg"
                  alt="piping system"
                />
                <div className="py-10  bg-gray-200 px-5 rounded-b ">
                  <h3 className="font-mont text-[#282828] font-bold text-lg  uppercase">
                    Pressure Piping System
                  </h3>
                  <p className="  text-start text-[#282828] mt-5 ">
                    With a focus on precision and reliability, Mondore Ltd excel
                    in the installation, maintenance, and repair of pressure
                    pipes essential to the oil and gas infrastructure, ensuring
                    the seamless flow and transportation of resources.
                  </p>
                </div>
              </div>

              <div className="col-span-2  " data-aos="fade-left">
                <img
                  src="https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_500,w_800/mondorelimited/services/work2_eq7arn.jpg"
                  alt="dd"
                />
                <div className="py-10   bg-gray-200 px-5  rounded-b ">
                  <h3 className="text-[#282828] font-bold text-xl uppercase">
                    Consultation
                  </h3>
                  <p className=" text-[#282828] mt-5 ">
                    Leveraging the expertise of our seasoned professionals, We
                    provides consultation services to guide clients through
                    project planning, strategy development, and risk assessment.
                    Our consultants bring industry insights to optimize project
                    outcomes.
                  </p>
                </div>
              </div>

              <div className="col-span-2" data-aos="fade-down">
                <img
                  src="https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_500,w_800/mondorelimited/services/logis1_t7ktgy.jpg"
                  alt="maintenace"
                />
                <div className="py-10  bg-gray-200 px-5  rounded-b">
                  <h3 className="text-[#282828] font-bold text-xl uppercase">
                    Procurement /Logistics
                  </h3>
                  <p className=" text-[#282828] mt-5 ">
                    Mondore Ltd facilitates efficient procurement and logistics
                    solutions, streamlining the supply chain for our clients.
                    Our meticulous approach ensures timely and cost-effective
                    sourcing of materials and equipment required for project
                    execution
                  </p>
                </div>
              </div>

              <div className="col-span-2 " data-aos="fade-right">
                <img
                  src="https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_500,w_800/mondorelimited/services/maintenances_ifcfwy.jpg"
                  alt="maintenace"
                />
                <div className="py-10   bg-gray-200 px-5 rounded-b ">
                  <h3 className="text-[#282828] font-bold text-xl uppercase">
                    Maintenances
                  </h3>
                  <p className=" text-[#282828] mt-5  ">
                    Recognizing the critical importance of ongoing maintenance,
                    Mondore Ltd offers comprehensive services to ensure the
                    longevity and optimal performance of oil and gas facilities.
                    Our proactive approach minimizes downtime and maximizes
                    operational efficiency.
                  </p>
                </div>
              </div>
            </div>

            <section className="  rounded-xl">
              <div className=" col-span-3 partners space-y-8 pl-5">
                <div className="mx-auto grid lg:grid-cols-4 grid-cols-2 gap-4 lg:gap-10 items-center text-center  px-4 lg:px-10  justify-center">
                  {clients.map((logo) => (
                    <img
                      data-aos="fade-in"
                      data-aos-delay="100"
                      data-aos-offset="10"
                      className=""
                      width={100}
                      src={logo}
                      alt={`quest ${logo}`}
                    />
                  ))}
                </div>
              </div>
            </section>

            <div className="lg:grid grid-cols-6 my-8 gap-10 lg:mt-28">
              <div
                className="imgs col-span-3 flex flex-col lg:order-1 "
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <img
                  className="w-4/5 rounded-lg shadow-md "
                  src={dredgin2}
                  alt="mondore maintenace"
                  srcSet=""
                />
               
              </div>
              <div
                className="text col-span-3 self-center mt-10 lg:mt-0"
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <h2 className="text-3xl font-bold text-gray-700 mb-8 capitalize text-center lg:text-start">
                  Experience the Mondore Advantage — Where Engineering Meets
                  Excellence.
                </h2>
                <p className=" text-gray-600 pb-10">
                  Whether it's dredging, pressure pipes works,
                  procurement/logistics, engineering construction, or
                  maintenance, Mondore Ltd is your trusted partner. We are
                  committed to delivering innovative, reliable, and sustainable
                  solutions that propel the success of our clients in the
                  dynamic Oil and Gas industry.
                </p>
                <div className="btn flex justify-center pb-5 lg:block ">
                  <Link to={"/contact-us"}>
                    <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600  text-lg">
                      Contact Us Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Service;
