import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import site_info from "../../assets/profile";
import Logo from "./logo";
import "./header.css";
import Container from "../container";

function Header({ title }) {
  const nav_ref = useRef();
  const top_nav = useRef();

  function handle_menu() {
    nav_ref.current.classList.toggle("hidden");
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // top_nav.current.classList.add("fixed")
    });
    let page_title = window.document.querySelector("head > title");
    page_title.textContent = `${title}`;
  });

  function handleScroll(e) {}

  function activenav(isActive) {
    return isActive ? "border-b-warning border-b" : " ";
  }

  return (
    <header className="shadow-md">
      <Container>
        <div className="py-2 hidden lg:block">
          <div
            ref={top_nav}
            onScroll={handleScroll}
            className="container  mx-auto lg:flex bg-white  scroll-smooth "
          >
            <div className="w-12/12 lg:w-5/12 ">
              <Logo
                name={site_info.name}
                title_class={"text-primary text-3xl font-bold"}
              />
            </div>
            <div className="w-full lg:w-7/12 self-center ">
              <div className="address w-full block lg:flex justify-end">
                <div className="flex mt-5 lg:mt-0 px-5 w-full gap-3 items-center">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="blue"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                      />
                    </svg>
                  </div>
                  <div className="text">
                    <a href={`tel:+${site_info.phone}`}> {site_info.phone}</a>{" "}
                    <br />
                    <Link to={`mailto:${site_info.email}`}>
                      {site_info.email}
                    </Link>
                  </div>
                </div>
                <span className="divider_v"></span>

                <div className="flex  w-full  px-3 gap-3 items-center">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="blue"
                      className="w-6 h-6 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>
                  </div>
                  <div className="text">
                    <p
                      className="address"
                      dangerouslySetInnerHTML={{ __html: site_info.address }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <nav className="bg-gray-100 shadow-md py-2   lg:py-3">
        <Container>
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-lg">
            <Logo
              name={site_info.name}
              visibility="lg:hidden"
              icon_height="40px"
              title_class={"text-primary text-2xl font-bold"}
            />

            <button
              onClick={handle_menu}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5 text-primary"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div
              ref={nav_ref}
              className="w-full lg:flex lg:w-auto items-center  hidden"
            >
              <ul className="flex flex-col py-[0.3rem] mt-4 font-medium lg:flex-row lg:space-x-2 space-y-4 lg:space-y-0 lg:mt-0">
                <li className=" self-start ">
                  <NavLink
                    to={"/"}
                    className={({ isActive }) =>
                      activenav(isActive).concat(
                        " hover:border-b- hover:border-b py-1 px-3 text-lg  transition-all delay-50 duration-300 ease-in-out"
                      )
                    }
                  >
                    Home
                  </NavLink>
                </li>

                <li className="self-start">
                  <NavLink
                    to={"/services"}
                    className={({ isActive }) =>
                      activenav(isActive).concat(
                        " hover:border-b-warning hover:border-b py-1 px-3 text-lg transition-all delay-50 duration-300 ease-in-out"
                      )
                    }
                    aria-current="page"
                  >
                    Products and Services
                  </NavLink>
                </li>
                <li className="self-start">
                  <NavLink
                    to={"/about"}
                    className={({ isActive }) =>
                      activenav(isActive).concat(
                        " hover:border-b-warning hover:border-b py-1 px-3 text-lg transition-all delay-50 duration-300 ease-in-out"
                      )
                    }
                  >
                    About Us
                  </NavLink>
                </li>

                <li className="self-start">
                  <NavLink
                    to={"/contact-us"}
                    className={({ isActive }) =>
                      activenav(isActive).concat(
                        " hover:border-b-warning hover:border-b py-1 px-3 text-lg transition-all delay-50 duration-300 ease-in-out"
                      )
                    }
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="btn hidden lg:inline-flex first-letter:">
              <NavLink
                to={"/contact-us"}
                className=" self-end ml-auto  py-1 px-6 outline outline-offset-0 focus:outline-none rounded "
              >
                Contact us
              </NavLink>
            </div>
          </div>
        </Container>
      </nav>
    </header>
  );
}

export default Header;
