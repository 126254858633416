import site_info from "../../assets/profile";
import { Link } from "react-router-dom";
import Logo, { LogoIcon } from "./logo";
import Container from "../container";

const Footer = () => (
  <footer className="text-dark font-normal">
    <Container>
      <div className="top_footer py-10 pt-20 ">
        <div className=" mx-auto md:grid lg:grid-cols-9 md:grid-cols-9 gap-4 space-y-7 md:space-y-0">
          <div className="col-span-3">
            <div className="top_footer-left flex gap-1 items-end ">
              <Logo
                name={site_info.name}
                title_class={"font-bold text-lg text-dark"}
                icon_height="30"
                icon_color="white"
              />
            </div>
            <p className="body  mt-2">
              Building a strong and distinctive reputation for quality and
              provision of efficient services to the energy and natural resource
              industry in Nigeria.
            </p>
            <p className="mt-3 font-serif font-bold">Follow Us on</p>
            <div className="social grid grid-cols-8 pt-2  ">
              <Link
                className="hover:scale-110 transition-all"
                data-tooltip-target="tooltip-linkedin"
                target="blank"
                to={"https://www.linkedin.com/"}
              >
                <svg
                  baseProfile="tiny"
                  viewBox="0 0 24 24"
                  fill="#212529"
                 height="2em"
                  width="2em"
                >
                  <path d="M10.033 15.3h-1.6v-5.199h1.6V15.3zm-.8-5.866c-.577 0-.866-.267-.866-.8a.74.74 0 01.25-.567.868.868 0 01.616-.233c.577 0 .866.268.866.801s-.288.799-.866.799zm6.734 5.866h-1.633v-2.9c0-.755-.268-1.133-.801-1.133-.422 0-.699.211-.834.633-.043.067-.066.201-.066.4v3H11v-3.533c0-.8-.012-1.355-.033-1.666h1.4l.1.699c.367-.556.9-.833 1.633-.833.557 0 1.006.194 1.35.583.346.389.518.95.518 1.684V15.3zM12 21c-4.963 0-9-4.037-9-9s4.037-9 9-9 9 4.037 9 9-4.037 9-9 9zm0-16c-3.859 0-7 3.141-7 7s3.141 7 7 7 7-3.141 7-7-3.141-7-7-7z" />
                </svg>
              </Link>
              <div
                id="tooltip-linkedin"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip "
              >
                Linkedin
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
              <Link
                data-tooltip-target="tooltip-twitter"
                target="blank"
                to={"https://facebook.com"}
                className="hover:scale-110 transition-all"
              >
                <svg
                  baseProfile="tiny"
                  viewBox="0 0 24 24"
                  fill="#212529"
                 height="2em"
                  width="2em"
                >
                  <path d="M15.279 10.283c.358-.221.597-.521.713-.904a3.854 3.854 0 01-1.045.383 1.55 1.55 0 00-1.182-.507c-.464 0-.855.163-1.175.479a1.585 1.585 0 00-.478 1.158c0 .137.017.26.052.364-1.368-.048-2.499-.614-3.391-1.706a1.644 1.644 0 00-.227.82c0 .578.244 1.036.73 1.373a1.81 1.81 0 01-.73-.209c0 .413.121.758.365 1.062.243.3.557.492.939.573a1.734 1.734 0 01-.435.053.826.826 0 01-.296-.037c.104.337.296.609.574.818.277.21.597.32.957.33a3.196 3.196 0 01-2.035.694c-.188 0-.32-.002-.4-.017a4.534 4.534 0 002.521.733c.951 0 1.792-.241 2.522-.723.73-.479 1.271-1.07 1.617-1.767a4.793 4.793 0 00.521-2.174v-.209c.336-.253.609-.538.818-.854a3.452 3.452 0 01-.935.267zM12 21c-2.49 0-4.635-.89-6.376-2.646C3.883 16.603 3 14.465 3 12c0-2.488.884-4.634 2.627-6.375C7.367 3.884 9.511 3 12 3c2.466 0 4.604.883 6.354 2.624C20.11 7.366 21 9.512 21 12c0 2.465-.889 4.604-2.644 6.357C16.605 20.11 14.467 21 12 21zm0-16c-1.966 0-3.588.667-4.958 2.04C5.667 8.412 5 10.034 5 12c0 1.944.668 3.562 2.043 4.945C8.415 18.328 10.036 19 12 19c1.943 0 3.56-.673 4.942-2.057C18.327 15.559 19 13.941 19 12c0-1.963-.672-3.585-2.055-4.957C15.562 5.668 13.945 5 12 5z" />
                </svg>
              </Link>
              <div
                id="tooltip-twitter"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip "
              >
                Twitter
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>

              <Link
                className="hover:scale-110 transition-all"
                data-tooltip-target="tooltip-default"
                target="blank"
                to={"https://facebook.com"}
              >
                <svg
                  baseProfile="tiny"
                  viewBox="0 0 24 24"
                  fill="#212529"
                 height="2em"
                  width="2em"
                >
                  <path d="M18 3H6C4.3 3 3 4.3 3 6v12c0 1.7 1.3 3 3 3h12c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3zm-6 6c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm3.8-2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2zM18 19H6c-.6 0-1-.4-1-1v-6h2c0 2.8 2.2 5 5 5s5-2.2 5-5h2v6c0 .6-.4 1-1 1z" />
                </svg>
              </Link>
              <div
                id="tooltip-default"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip "
              >
                Instagram
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
              <Link
                className="hover:scale-110 transition-all"
                data-tooltip-target="tooltip-facebook"
                target="blank"
                to={"https://facebook.com"}
              >
                <svg
                  baseProfile="tiny"
                  viewBox="0 0 24 24"
                  fill="#212529"
                 height="2em"
                  width="2em"
                >
                  <path d="M18.354 5.624C16.604 3.883 14.466 3 12 3c-2.489 0-4.633.884-6.373 2.625C3.884 7.366 3 9.512 3 12c0 2.465.883 4.603 2.624 6.354C7.365 20.11 9.51 21 12 21c2.467 0 4.605-.89 6.356-2.643C20.111 16.604 21 14.465 21 12c0-2.488-.89-4.634-2.646-6.376zm-1.412 11.319c-1.137 1.139-2.436 1.788-3.942 1.985V14h2v-2h-2v-1.4a.6.6 0 01.601-.6H15V8h-1.397c-.742 0-1.361.273-1.857.822-.496.547-.746 1.215-.746 2.008V12H9v2h2v4.93c-1.522-.195-2.826-.845-3.957-1.984C5.668 15.562 5 13.944 5 12c0-1.966.667-3.588 2.042-4.96C8.412 5.667 10.034 5 12 5c1.945 0 3.562.668 4.945 2.043C18.328 8.415 19 10.037 19 12c0 1.941-.673 3.559-2.058 4.943z" />
                </svg>
              </Link>
              <div
                id="tooltip-facebook"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip "
              >
                FaceBook
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          </div>
          <div className="pt-3 col-span-2">
            <h2 className="text-xl font-semibold">Services</h2>
            <ul className=" list-inside space-y-1 ">
              <li className="list-item ">
                <Link to="/services">Dredging</Link>
              </li>
              <li className="list-item ">
                <Link to="/services">Civil Construction</Link>
              </li>
              <li className="list-item ">
                <Link to="/services">Logistics</Link>
              </li>
              <li className="list-item ">
                <Link to="/services">Consultation and Design</Link>
              </li>
              <li className="list-item ">
                <Link to="/services">infrastructure Maintenances </Link>
              </li>
              <li className="list-item ">
                <Link to="/services">Maritime Infrastructure</Link>
              </li>
            </ul>
          </div>
          <div className="pt-4 col-span-1">
            <h2 className="text-xl font-semibold">Links</h2>
            <ul className=" list-inside space-y-1 ">
              <li className="  flex ">
                <Link to="/">Home</Link>
              </li>
              <li className="list-item  text-blue-600 ">
                <Link to="/services">Services</Link>
              </li>
              <li className="list-item  text-blue-600 ">
                <Link to="/">Projects</Link>
              </li>
              <li className="list-item  text-blue-600 ">
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="pt-4 col-span-3">
            <h2 className="text-xl font-semibold mb-2">Address</h2>

            <div className="flex  w-full  gap-3 items-center">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="grey"
                  className="w-6 h-6 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </div>
              <div className="text">
                <p
                  className="address"
                  dangerouslySetInnerHTML={{ __html: site_info.address }}
                ></p>
              </div>
            </div>
            <div className="flex mt-3   w-full gap-3 items-center">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="grey"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                  />
                </svg>
              </div>
              <p className="text">{site_info.phone}</p>
            </div>
            <div className="flex mt-3  w-full gap-3 items-center font-mono">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="grey"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
              <p className="text">{site_info.email}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <div className="footer_note bg-gray-200 font-normal">
      <Container>
        <p className="text-sm text-center py-2 flex justify-center items-baseline">
          All Right Reserved &copy; &nbsp;
          <LogoIcon height="30px" className="iinline-flex" />{" "}
          <strong className="font-sans text-sm">{site_info.name}</strong> &nbsp;
          {new Date().getFullYear()}
        </p>
      </Container>
    </div>
  </footer>
);

export default Footer;
